import ImportKnowledgeBase from "../../components/forms/ImportKnowledgeBase";
import { AddCorporationForm } from "../../components/forms/AddCorporationForm";
import AddUserToCorporationForm from "../../components/forms/AddUserToCorporationForm";
import AddUserToPropertyForm from "../../components/forms/AddUserToPropertyForm";
import SendWelcomeEmail from "../../components/forms/SendWelcomeEmail";
import MigrateTicketCategories from "../../components/forms/MigrateTicketCategories";
import ImportTranslations from "../../components/forms/ImportTranslations";
import RevertMoveOutForm from "../../components/forms/RevertMoveOutForm";
import CopyKnowledgeBase from "../../components/forms/CopyKnowledgeBase";
import DownloadKnowledgeBase from "../../components/forms/DownloadKnowledgeBase";
import GenerateTenancyReport from "../../components/forms/GenerateTenancyReport";
import SalesforceSyncFalsePositive from "../../components/forms/SalesforceSyncFalsePositive";
import ResetPayment from "../../components/forms/ResetPayment";
import BackupDatabase from "../../components/forms/BackupDatabase";
import UpdateCorporationPaymentFee from "../../components/forms/UpdateCorporationPaymentFee";

export default function DashboardContainer() {
    return (
        <>
            <AddUserToPropertyForm />
            <ResetPayment />
            <RevertMoveOutForm />
            <GenerateTenancyReport />
            <AddUserToCorporationForm />
            <AddCorporationForm />
            <ImportKnowledgeBase apiFields={["type", "category", "inquiry", "answer", "localizedtype", "localizedcategory", "localizedinquiry", "localizedanswer", "project", "allowcontactticket"]} />
            <CopyKnowledgeBase />
            <DownloadKnowledgeBase />
            <SendWelcomeEmail />
            <MigrateTicketCategories />
            <ImportTranslations apiFields={["translationKey", "danishTranslation", "spanishTranslation", "englishTranslation", "swedishTranslation"]} />
            <SalesforceSyncFalsePositive />
            <BackupDatabase />
            <UpdateCorporationPaymentFee/>
            <h1>&nbsp;</h1>
        </>
    )
}