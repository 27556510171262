import { useEffect, useState } from "react";
import { Button, ButtonTypes } from "../Button";
import { Alert, AlertTypes } from "../Alert";
import { updateCorporationPaymentFee } from "../../api/tools";
import { getAllCorporations } from "../../helpers/corporation";
import { SelectInput } from "../SelectInput";
import TextInput, { InputTypes } from "../TextInput";
import { Modal } from "../Modal";

export default function UpdateCorporationPaymentFee() {
    const [corporationId, setCorporationId] = useState(0);
    const [allCorporations, setAllCorporations] = useState([]);
    const [errorResponse, setErrorResponse] = useState('');
    const [successReponse, setSuccessResponse] = useState('');
    const [showModal, setShowModal] = useState(false);

    const [fee, setFee] = useState(0);
    const MIN_FEE = 0.001;

    useEffect(() => {
        getAllCorporations().then(corporations => {
            if (corporations) {
                setAllCorporations(corporations as any);
            }
        })
    }, [])

    async function handleSubmit(e: Event) {
        e.preventDefault();
        setShowModal(true);
    }

    async function submit(e: Event) {
        e.preventDefault();
        resetState();
        const response = await updateCorporationPaymentFee({corporationId, fee });
        if (response?.exception) {
            setErrorResponse(response?.message);
        } else {
            setSuccessResponse(response?.message);
        }
    }

    function isFormValid() {
        return fee && corporationId && fee >= MIN_FEE && fee < 1;
    }

    function resetState() {
        setErrorResponse('');
        setSuccessResponse('');
        setShowModal(false);
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="card mb-4 full-width">
                        <h5 className="card-header">Update corporation payment fee</h5>
                        <div className="card-body">
                            <div className="col-md-6">
                                <SelectInput
                                    label="Corporation"
                                    options={
                                        allCorporations
                                    }
                                    placeholder={
                                        {
                                            value: '',
                                            label: 'Select corporation'
                                        }
                                    }
                                    value={corporationId}
                                    onValue={(option: any) => setCorporationId(option.value)}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextInput
                                    onValue={(value: number) => setFee(value)}
                                    label="Corporation one time payment percentage fee"
                                    placeholder="0.035"
                                    description="Percentage fee from order amount that is transferred to Hococo. On default it's 0.035"
                                    type={InputTypes.NUMBER}
                                    htmlOptions={{ min: MIN_FEE }}
                                />
                            </div>
                            <div className="pt-4">
                                <Button type={ButtonTypes.PRIMARY} text="Update fee" onPress={(e: Event) => handleSubmit(e)} className={!isFormValid() ? 'disabled' : ''} />
                            </div>
                            {errorResponse && (
                                <div className="pt-4">
                                    <Alert type={AlertTypes.DANGER} text={errorResponse} />
                                </div>
                            )}
                            {successReponse && (
                                <div className="pt-4">
                                    <Alert type={AlertTypes.SUCCESS} text={successReponse} />
                                </div>
                            )}
                            {showModal && (
                                <Modal
                                    title="Are you sure you want to update Hococo payment fee?"
                                    onConfirm={(e: Event) => submit(e)}
                                    onCancel={(e: Event) => setShowModal(false)}
                                >
                                    <Alert type={AlertTypes.DANGER} text="This will update the fee for the all the one time payments for chosen corporation." /> 
                                </Modal>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}